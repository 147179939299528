<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Factura"
      :subtitle="invoiceId > 0 ? '|  Editar Factura' : ' | Nueva Factura'"
      class="heading-block"
    />    
    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
          <b-row>
            <b-col sm="8">
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          # de Cotización: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="factura.quote.id"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Cliente: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="factura.quote.client.nombre_comercial"
                          size="sm"
                          disabled
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12"></b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Folio factura: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="factura.folio_factura"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Subtotal factura: <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="factura.subtotal_factura"
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12"></b-col>
                  <b-col sm="4" class="mt-2" v-if="factura.id<=0">
                    <b-button variant="alt-primary"  class="mr-1"  @click="Option()" >Seleccionar cotización</b-button>
                  </b-col>
                </b-row>
              </form>
            </b-col>
            <b-col sm="4" class="rounded border p-4 pt-0">
              <FileSystem v-if="quote_id != ''" view="cotizacion" :idItemView="quote_id" :files="files"></FileSystem>
            </b-col>
          </b-row>

          <b-row>

            <b-row>
                  <!-- MODAL -->
                  <b-modal
                    id="modal-items"
                    size="xl"
                    body-class="p-0"
                    hide-footer
                    hide-header
                    >
                    <div class="block block-rounded block-themed block-transparent mb-0">
                      <div class="block-header bg-primary-dark">
                        <h3 class="block-title">
                          <i class="fa fa-user mr-1"></i> Lista
                        </h3>
                          <div class="block-options">
                            <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-items')"></i>
                          </div>
                      </div>
                      <div class="block-content font-size-sm">
                        <b-row>
                          <b-col offset-sm="9" sm="2">
                            <b-form inline>
                              <b-form-group label-for="no_cliente">
                                <template>
                                  Buscar:
                                </template>
                                <b-form-input
                                  v-model="searchTerm"
                                  size="sm"
                                ></b-form-input>
                              </b-form-group>
                            </b-form>
                          </b-col>
                        </b-row>
                        <div class="table-responsive">
                          <template>
                            <b-table  
                              @row-clicked="SelectItemQuote"
                              :items="quotesFilter"
                              :fields="fields_quotes"
                              :current-page="currentPage"
                              :per-page="perPage"
                              responsive
                              striped
                              hover>
                            </b-table>
                          </template>
                        </div>
                      </div>
                      <b-row>
                        <b-col sm="7" md="2" class="my-1">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalFilteredRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                      <div class="block-content block-content-full text-right border-top">
                        <b-button
                          variant="alt-primary"
                          class="mr-1"
                          @click="$bvModal.hide('modal-items')"
                        >Cerrar</b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- FIN MODAL -->
                </b-row>

          </b-row>

          <br />
        </div>
        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" class="mr-1" @click="factura.id > 0 ? UpdateFactura() : CreateFactura()" :disabled="factura.quote.id > 0 ? false:true " >Guardar factura</b-button>
        </div>
        </base-block>
    </div>
  </div>
</template>

<script>

  import Facturas from "../../../api/admin/facturas/Facturas";
  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion"
  import FileSystem from "../../../components/FileSystem.vue"
  import Swal from "sweetalert2"

  export default {

    components: {
      FileSystem
    },

    data() {
      return {
        invoiceId:false,
        quote_id:0,
        files:[],
        quotes:[],
        factura:{id:0, quote:{client:'--'}},
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        searchTerm:"",
        totalRows:0,
        perPage: 5,
        currentPage: 1,
        fields_quotes: [
          {key: 'id',sortable: true, label: 'NÚMERO DE COTIZACIÓN'},
          {key: 'client.nombre_comercial',sortable: true, label: 'CLIENTE'},
          {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
          {key: 'total',sortable: true, label: 'TOTAL'},
          {key: 'estado',sortable: true, label: 'ESTADO'}
        ],
      }
    },

    methods: {

      Cancelar(){
        this.$router.push({
          path: "/facturas/list"
        });
      },

      /* Para las facturas*/

      GetFacturaInfo(id){
        const context = this
        let auth = JSON.parse(localStorage.autentication);
        Facturas.ViewFactura(id,auth)
        .then((response) => {
          console.log(response.data)
          context.factura = response.data;
          context.showoverlay = false;
          context.quote_id = context.factura.quote.id
        })
        .catch((error) => {
          console.log(error.data);
        })
      },

      UpdateFactura(){
        let auth = JSON.parse(localStorage.autentication)
        const context = this
        Facturas.UpdateFactura(context.factura,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          context.GetFacturaInfo(context.invoiceId)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      CreateFactura(){
        const context = this
        Swal.fire({
        title: "Esta operación actualizará el estado de la cotización a 'Facturado'",
        text: "¿Desea continuar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Sí, actualizar",
        cancelButtonText: "Cancelar",
      })
      .then(resultado => {
        if (resultado.value) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          const data = {
            folio_factura:context.factura.folio_factura,
            subtotal_factura:context.factura.subtotal_factura,
            quote_id:context.factura.quote.id
          }
          Facturas.CreateFactura(data, auth)
          .then((response) => {
            console.log(response);
            this.$router.push({
              path: "/facturas/edit/"+response.data.data.id,
              params:{
                id: response.data.data.id,
              }
            });
            this.showoverlay = false;
            Swal.fire({
              title: "Genial!",
              text: response.data.message,
              icon: "success",
            }).then(() => window.location.reload());
          })
          .catch((error) => {
            Swal.fire({
              title: "Que mal!",
              text: error.message,
              icon: "error",
            });
          });
        } else {
          console.log("*NO se elimina la Factura*");
        }
      });
      },

      Option() {

        //let option = event.target.value
        let auth = JSON.parse(localStorage.autentication);

        //console.log(option)

        const context = this

        context.searchTerm = ""

        PreCotizacion.GetAllQuot(auth)
          .then((response) => {
            context.quotes = response.data;
            context.$bvModal.show("modal-items");
            context.totalRows = context.quotes.length
            console.log("quotes")
            console.log(context.quotes)
          })
          .catch((error) => {
            console.log(error.data);
          });
      },

      SelectItemQuote(data_select){
        console.log(data_select)
        const context = this
        context.$bvModal.hide("modal-items"); 
        if(data_select.estado == 'facturado'){
          Swal.fire({
            title: "¡Esta cotización ya está facturada!",
            text: "Puede eliminarda y crearla de nuevo desde la lista de facturas.",
            icon: "error",
          });
          return
        }
        context.factura.quote = data_select
      }
      
    },

    computed:{
      quotesFilter(){
        return this.quotes.filter((item) => {
          if (this.searchTerm === "") {
            if(!item.estado.toLowerCase().includes('facturado')){
              return item;
            }
          } else if ( 
            (item.estado.toLowerCase().includes(this.searchTerm.toLowerCase())) || 
            (item.descripcion && item.descripcion.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (item.total && item.total.toString().toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (item.comentarios && item.comentarios.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (item.client.nombre_comercial && item.client.nombre_comercial.toLowerCase().includes(this.searchTerm.toLowerCase()))
          ) {
            return item;
          }
        })
      },
      totalFilteredRows(){
        return this.quotesFilter.length
      }
    },   

    mounted() {
      let me = this
      me.invoiceId = me.$route.params.id
      if(me.invoiceId > 0){
        // buscar el id de la factura
        me.GetFacturaInfo(me.invoiceId)
      } else {
        // crear nueva factura
      }
    }
  };



</script>


<style scoped>
    #button_accordion{
      background: #272e38;
      border-color: #272e38;
    }

    #titulo {
      text-align: center;
    }

    hr#linea {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #030303;
    }

    hr{
      border-top: 1px solid #000000 !important;
    }

    .v-line{
      border-left: solid #e8dbdb;
      height: 57%;
      left: 63%;
      position: absolute;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

</style>